import React from 'react';
import { styled } from '@mui/material/styles';
import { Typography, Box } from '@mui/material';
import { useTranslation } from 'next-i18next';

import ReviewStates from 'views/common/components/UI/ReviewStates';

const PREFIX = 'HelpfulReview';

const classes = {
  reviewVotes: `${PREFIX}-reviewVotes`,
  helpfulText: `${PREFIX}-helpfulText`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')(({ theme, reviewVotesCount }) => ({
  [`& .${classes.reviewVotes}`]: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: reviewVotesCount > 0 ? 'space-between' : 'flex-end',
    borderTop: `1px solid ${theme.palette.grey[50]}`,
    paddingTop: theme.spacing(1.5),
    [theme.breakpoints.up('md')]: {
      justifyContent: 'flex-end',
      borderTop: 0,
    },
  },

  [`& .${classes.helpfulText}`]: {
    color: theme.palette.grey['200'],
    marginRight: theme.spacing(1.5),
  },
}));

const HelpfulReview = (props) => {
  const { reviewVotes } = props;

  const { t } = useTranslation('fe_er_reviews_card');

  return (
    <Root reviewVotesCount={reviewVotes.count}>
      {reviewVotes && (
        <Box className={classes.reviewVotes}>
          {reviewVotes.count > 0 && (
            <Typography variant="body2" className={classes.helpfulText}>
              {t('fe_er_reviews_card:found_review_helpful', {
                votes_count: `+${reviewVotes.count}`,
              })}
            </Typography>
          )}

          <ReviewStates
            currentUserVote={reviewVotes.currentUserVote}
            handleVotingChange={reviewVotes.handleVotingChange}
          />
        </Box>
      )}
    </Root>
  );
};

export default HelpfulReview;
