import React from 'react';
import { Button } from '@mui/material';
import { useTranslation } from 'next-i18next';

import ReviewOverlay from 'views/common/components/UI/MyReview/ReviewOverlay';

const EditReview = (props) => {
  const { reviewOverlayData } = props;
  const { t } = useTranslation('fe_er_my_review');

  return (
    <>
      <Button
        color="primary"
        onClick={reviewOverlayData.state.handleOpen}
        style={{ textTransform: 'none' }}>
        {t('fe_er_my_review:edit_review')}
      </Button>

      <ReviewOverlay
        title={t('fe_er_my_review:edit_review')}
        reviewOverlayData={reviewOverlayData}
      />
    </>
  );
};

export default EditReview;
