import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledBox = styled(Box)(({ theme, size, error }) => ({
  position: 'relative',
  height: '100%',
  '&:hover': {
    '& .IBIcon path': {
      fill: error ? theme.palette.error.main : theme.palette.info.main,
    },
  },
  '& .IBIcon': {
    position: 'absolute',
    zIndex: 1,
    top: '50%',
    transform: 'translateY(-50%)',
    left: theme.spacing(1.5),
    width: size || theme.typography.pxToRem(28),
    height: size || theme.typography.pxToRem(28),
    '&  path': {
      transition: 'fill .3s',
      fill: error ? theme.palette.error.main : theme.palette.grey[600],
    },
  },
}));

export default StyledBox;
