import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledBox = styled(Box)(({ theme }) => ({
  '& .ESPTitleGrid': {
    justifyContent: 'center',
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('xl')]: {
      alignItems: 'center',
    },
  },
  '& .ESPTitle': {
    color: theme.palette.secondary.dark,
    marginBottom: theme.spacing(1.5),
    [theme.breakpoints.down('md')]: {
      marginBottom: theme.spacing(4),
    },
    [theme.breakpoints.down('lg')]: {
      marginBottom: theme.spacing(2),
    },
  },
  '& .ESPSeeMoreBtn': {
    padding: theme.spacing(0),
    marginTop: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      marginTop: theme.spacing(3),
    },
    [theme.breakpoints.up('xl')]: {
      marginTop: theme.spacing(0),
    },
  },
  '& .ESPCard': {
    borderRadius: theme.spacing(2),
    border: ` 1px solid ${theme.palette.grey[100]}`,
    overflow: 'hidden',
  },
  '& .ESPCardText': {
    padding: theme.spacing(2),
    color: theme.palette.grey[600],
  },
  '& .ESPCardTitle': {
    fontWeight: 300,
  },
  '& .ESPCardDesc': {
    fontSize: theme.spacing(1.625),
  },
  [theme.breakpoints.up('xl')]: {
    '& .showTABLET': {
      display: 'none',
    },
  },
  [theme.breakpoints.down('xl')]: {
    '& .showDESKTOP': {
      display: 'none',
    },
  },
  [theme.breakpoints.down('md')]: {
    '& .hiddenMOBILE': {
      display: 'none',
    },
  },
}));

export default StyledBox;
