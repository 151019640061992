/* eslint-disable react/destructuring-assignment */
import React from 'react';
import { TextField } from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledTextField = styled(TextField)(({ theme, isautocomplete }) => ({
  '& label': {
    color: 'rgb(158, 158, 158)',
  },
  '&, &:hover': {
    '& .MuiFilledInput-root': {
      backgroundColor: theme.palette.primary.contrastText,
      borderColor: '#aeaeae',
      borderWidth: '1px',
      borderStyle: 'solid',
      borderRadius: theme.spacing(0),
      '&:before': {
        borderBottom: 'none !important',
      },
      '&:after': {
        transform: 'none',
        opacity: '0',
        transition: 'opacity .4s',
      },
      '&.Mui-focused ': {
        backgroundColor: theme.palette.primary.contrastText,
        borderColor: isautocomplete && theme.palette.info.main,
        '&:after': {
          opacity: '1',
          boxShadow: 'rgb(93, 93, 93) 0px 1px 0px 0px',
          borderBottom: '1px solid rgb(93, 93, 93)',
          borderColor: isautocomplete && theme.palette.info.main,
        },
      },
      '&.Mui-disabled': {
        opacity: '80%',
        borderStyle: 'dashed',
        borderBottomStyle: 'dotted',
        cursor: 'not-allowed',
        backgroundColor: 'rgba(0,0,0,0.05)',
        '&:after': {
          opacity: '1',
          boxShadow: 'none',
          borderBottom: 'none',
        },
        '& input': {
          cursor: 'not-allowed',
        },
      },
      '&.Mui-error': {
        '&:after': {
          opacity: '1',
          boxShadow: '#f44336 0px 1px 0px 0px',
          borderBottom: '1px solid #f44336',
        },
      },
    },
  },
}));
const OldTextField = (props) => {
  const { variant, isautocomplete, ...restOfProps } = props;
  const variants = { variant: 'filled' };
  if (variant) {
    variants.variant = props.variant;
  }

  return (
    <StyledTextField
      isautocomplete={isautocomplete}
      {...restOfProps}
      {...variants}
    />
  );
};

export { OldTextField };
