import React from 'react';
import { Button, ButtonGroup } from '@mui/material';
import { styled } from '@mui/material/styles';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';

import IconButtonBordered from 'views/common/components/UI/IconButtonBordered';

const Root = styled('div')(({ theme, inactive }) => ({
  '& .buttonGroup': {
    height: theme.spacing(3.75),
    borderTop: `1px solid ${theme.palette.grey[100]}`,
    borderBottom: `1px solid ${theme.palette.grey[100]}`,
  },
  '& .counterBtn': {
    borderRadius: 4,
    '&.MuiButton-root': {
      padding: theme.spacing(0.5),
    },
    '&.MuiButtonGroup-groupedOutlinedHorizontal:not(:last-child)': {
      borderRightColor: 'rgba(254, 123, 16, 0.5)',
      '&::hover': {
        borderRightColor: theme.palette.primary.main,
      },
    },
    '&:hover.MuiButtonGroup-groupedOutlinedHorizontal:not(:last-child)': {
      borderRightColor: theme.palette.primary.main,
    },
    '&.Mui-disabled.MuiButtonGroup-groupedOutlinedHorizontal:not(:last-child)':
      {
        borderRightColor: 'rgba(0, 0, 0, 0.12)',
      },
    '&.MuiButtonGroup-groupedHorizontal:not(:last-child)': {
      borderRadius: 4,
    },
    '&.MuiButtonGroup-groupedHorizontal:not(:first-child)': {
      borderRadius: 4,
    },
  },
  '& .disabled': {
    "&[class*='Mui-disabled']": {
      color: !inactive && theme.palette.secondary.main,
    },
  },
}));

const Counter = ({
  count, min, max, onIncrement, onDecrement, inactive,
}) => (
  <>
    <Root inactive={inactive}>
      <ButtonGroup className="buttonGroup" color="primary">
        <IconButtonBordered
          color="primary"
          className="counterBtn"
          onClick={onDecrement}
          disabled={inactive || (min != null ? count <= min : false)}
          variant="contained">
          <RemoveIcon fontSize="small" />
        </IconButtonBordered>
        <Button variant="text" disabled className="disabled">
          {count}
        </Button>
        <IconButtonBordered
          color="primary"
          className="counterBtn"
          onClick={onIncrement}
          disabled={inactive || (max != null ? count >= max : false)}
          variant="contained">
          <AddIcon fontSize="small" />
        </IconButtonBordered>
      </ButtonGroup>
    </Root>
  </>
);

export { Counter };
