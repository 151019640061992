import React from 'react';
import StyledBox from 'views/common/components/UI/Reviews/Reviews.styles';
import ReviewsTitle from 'views/common/components/UI/ReviewsTitle';
import { useTranslation } from 'next-i18next';
import ReviewCard from 'views/common/components/UI/ReviewCard';
import dayjs from 'dayjs';
import { Button, Box } from '@mui/material';

const Reviews = (props) => {
  const { t } = useTranslation('fe_er_common_reviews');
  const {
    ratingLabel,
    reviewError,
    hasReview,
    addReviewState,
    onSubmitVote,
    editReviewState,
    reviewFormData,
    onDataChange,
    reviews,
    currentUserReview,
    submitReviewBtn,
    handleViewAllReviewsBtnClick,
    ratingLable,
    totalCount,
  } = props;

  return (
    <StyledBox>
      <ReviewsTitle
        title={t('fe_er_common_reviews:reviews')}
        reviewsCount={totalCount || reviews.length}
        reviewOverlayData={
          !hasReview && {
            data: reviewFormData,
            ratingLable: ratingLabel,
            onDataChange,
            submitReviewBtn,
            reviewError,
            state: addReviewState,
          }
        }
      />
      {currentUserReview && (
        <ReviewCard
          key={currentUserReview.id}
          reviewId={currentUserReview.id}
          userImage={currentUserReview.reviewer.avatar.small_url}
          userName={currentUserReview.reviewer.name}
          reviewDate={dayjs(currentUserReview.created_at).format(
            'MMMM D, YYYY HH:mm a',
          )}
          rate={currentUserReview.star_rating}
          reviewDetails={[
            {
              title: currentUserReview.title,
              description: currentUserReview.body,
            },
          ]}
          editReviewDetails={{
            data: reviewFormData,
            onDataChange,
            reviewError,
            ratingLable,
            submitReviewBtn,
            state: editReviewState,
          }}
          reviewVotes={{
            count: currentUserReview.votes,
            currentUserVote: currentUserReview.current_user_vote,
          }}
          onSubmitVote={onSubmitVote}
        />
      )}
      {reviews.map((review) => (
        <ReviewCard
          key={review.id}
          reviewId={review.id}
          userImage={review.reviewer.avatar.small_url}
          userName={review.reviewer.name}
          reviewDate={dayjs(review.created_at).format('MMMM D, YYYY HH:mm a')}
          rate={review.star_rating}
          reviewDetails={[
            {
              title: review.title,
              description: review.body,
            },
          ]}
          reviewVotes={{
            count: review.votes,
            currentUserVote: review.current_user_vote,
          }}
          onSubmitVote={onSubmitVote}
        />
      ))}
      {totalCount >= 3 && handleViewAllReviewsBtnClick && (
        <Box display="flex" justifyContent="flex-end">
          <Button
            variant="outlined"
            color="primary"
            className="RVRSViewAllBtn"
            onClick={handleViewAllReviewsBtnClick}>
            {`${t('fe_er_common_reviews:view_all_reviews', {
              count: totalCount,
            })}`}
          </Button>
        </Box>
      )}
    </StyledBox>
  );
};

export { Reviews };
