import React from 'react';
import { styled } from '@mui/material/styles';
import { Typography, Box, Tooltip } from '@mui/material';
import { useTranslation } from 'next-i18next';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import StarIcon from '@mui/icons-material/Star';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import clsx from 'clsx';

const PREFIX = 'UserInfoSubInfo';

const classes = {
  user: `${PREFIX}-user`,
  userImage: `${PREFIX}-userImage`,
  userName: `${PREFIX}-userName`,
  badges: `${PREFIX}-badges`,
  badge: `${PREFIX}-badge`,
  badge2: `${PREFIX}-badge2`,
  date: `${PREFIX}-date`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')(({ theme }) => ({
  [`& .${classes.user}`]: {
    display: 'flex',
    alignItems: 'center',

    '&.userCentered': {
      [theme.breakpoints.down('md')]: {
        alignItems: 'center',
        flexDirection: 'column',
      },
    },
  },

  [`& .${classes.userImage}`]: {
    width: theme.spacing(5.5),
    height: theme.spacing(5.5),
    borderRadius: '50%',
    overflow: 'hidden',
    marginRight: theme.spacing(1),
    display: 'inline-flex',
    justifyContent: 'center',
    flexShrink: '0',

    '& img': {
      display: 'block',
      width: 'auto',
      height: '100%',
    },

    [theme.breakpoints.up('md')]: {
      width: theme.spacing(8.75),
      height: theme.spacing(8.75),
      marginRight: theme.spacing(2),
    },

    '.userCentered &': {
      [theme.breakpoints.down('md')]: {
        marginRight: '0',
        marginBottom: theme.spacing(1.5),
      },
    },
  },

  [`& .${classes.userName}`]: {
    fontSize: theme.typography.pxToRem(16),
    fontWeight: '500',
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.secondary.light,

    '& svg': {
      marginLeft: theme.spacing(1.25),
      color: theme.palette.info.main,
      height: theme.typography.pxToRem(20),
    },

    [theme.breakpoints.up('md')]: {
      fontSize: theme.typography.pxToRem(20),
    },

    '.userCentered &': {
      justifyContent: 'center',
    },
  },

  [`& .${classes.badges}`]: {
    display: 'flex',
    marginTop: theme.spacing(1.75),
    flexWrap: 'wrap',

    [theme.breakpoints.down('md')]: {
      marginTop: theme.spacing(0.5),
    },

    '.userCentered &': {
      [theme.breakpoints.down('md')]: {
        justifyContent: 'center',
        marginTop: theme.spacing(1),
      },
    },
  },

  [`& .${classes.badge}`]: {
    backgroundColor: theme.palette.grey['50'],
    border: `1px solid ${theme.palette.grey['100']}`,
    padding: theme.spacing(0.375, 1.125),
    borderRadius: theme.typography.pxToRem(11),
    display: 'flex',
    alignItems: 'center',
    fontSize: theme.typography.pxToRem(14),
    lineHeight: 'normal',
    whiteSpace: 'nowrap',
    marginTop: theme.spacing(0.5),

    '& svg': {
      height: theme.typography.pxToRem(16),
      width: theme.typography.pxToRem(16),
      marginLeft: theme.spacing(0.5),
      color: theme.palette.warning.main,
    },

    '&:not(:last-child)': {
      marginRight: theme.spacing(1),
    },
  },

  [`& .${classes.badge2}`]: {
    backgroundColor: 'transparent',
    color: theme.palette.secondary.dark,

    '& svg': {
      color: theme.palette.grey['900'],
    },
  },

  [`& .${classes.date}`]: {
    color: theme.palette.grey['400'],
    lineHeight: theme.typography.pxToRem(14),
    [theme.breakpoints.up('md')]: {
      marginTop: theme.spacing(1.75),
    },
  },
}));

const UserInfoSubInfo = (props) => {
  const {
    image,
    name,
    verified,
    date,
    rate,
    trips,
    centered,
    commercialHostToolTip,
    classes: styles = {},
    className,
  } = props;

  const { t } = useTranslation('fe_er_user_info_sub_info_component');

  return (
    <Root className={className}>
      <Box
        className={`${classes.user} ${styles.user} ${
          centered ? 'userCentered' : ''
        }`}>
        <Box className={clsx(classes.userImage, styles.userImage)}>
          <img
            src={image || '/imgs/default/placeholder_user2.webp'}
            alt="user-avatar"
          />
        </Box>

        <Box className={clsx(classes.userInfo, styles.userInfo)}>
          <Box className={clsx(classes.userName, styles.userName)}>
            {name}

            {verified && <VerifiedUserIcon />}
          </Box>

          {(rate || trips) && (
            <Box className={clsx(classes.badges, styles.badges)}>
              {rate && (
                <span className={clsx(classes.badge, styles.badge)}>
                  <span>{rate}</span>

                  <StarIcon />
                </span>
              )}

              {trips && (
                <span className={clsx(classes.badge, styles.badge)}>
                  {trips}
                  {t('fe_er_user_info_sub_info_component:trips')}
                </span>
              )}

              {commercialHostToolTip && (
                <span
                  className={`${classes.badge} ${styles.badge} ${classes.badge2}`}>
                  {t('fe_er_user_info_sub_info_component:commercial_host')}

                  <Tooltip title={commercialHostToolTip} enterTouchDelay="50">
                    <HelpOutlineIcon />
                  </Tooltip>
                </span>
              )}
            </Box>
          )}

          {date && (
            <Typography
              variant="caption"
              component="p"
              className={clsx(classes.date, styles.date)}>
              {date}
            </Typography>
          )}
        </Box>
      </Box>
    </Root>
  );
};

export default UserInfoSubInfo;
