import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const IconSpecs = (props) => (
  <SvgIcon
    {...props}
    viewBox="0 0 14 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0.333313 0.999837C0.333313 0.778823 0.421111 0.566862 0.577391 0.410582C0.733671 0.254301 0.945633 0.166504 1.16665 0.166504H12.8333C13.0543 0.166504 13.2663 0.254301 13.4226 0.410582C13.5788 0.566862 13.6666 0.778823 13.6666 0.999837C13.6666 1.22085 13.5788 1.43281 13.4226 1.58909C13.2663 1.74537 13.0543 1.83317 12.8333 1.83317H1.16665C0.945633 1.83317 0.733671 1.74537 0.577391 1.58909C0.421111 1.43281 0.333313 1.22085 0.333313 0.999837Z"
      fill="currentColor"
    />
    <path
      d="M0.333313 10.9998C0.333313 10.7788 0.421111 10.5669 0.577391 10.4106C0.733671 10.2543 0.945633 10.1665 1.16665 10.1665H12.8333C13.0543 10.1665 13.2663 10.2543 13.4226 10.4106C13.5788 10.5669 13.6666 10.7788 13.6666 10.9998C13.6666 11.2209 13.5788 11.4328 13.4226 11.5891C13.2663 11.7454 13.0543 11.8332 12.8333 11.8332H1.16665C0.945633 11.8332 0.733671 11.7454 0.577391 11.5891C0.421111 11.4328 0.333313 11.2209 0.333313 10.9998Z"
      fill="currentColor"
    />
    <path
      d="M1.16665 5.1665C0.945632 5.1665 0.733671 5.2543 0.577391 5.41058C0.421111 5.56686 0.333313 5.77882 0.333313 5.99984C0.333313 6.22085 0.421111 6.43281 0.577391 6.58909C0.733671 6.74537 0.945632 6.83317 1.16665 6.83317H7.83331C8.05433 6.83317 8.26629 6.74537 8.42257 6.58909C8.57885 6.43281 8.66665 6.22085 8.66665 5.99984C8.66665 5.77882 8.57885 5.56686 8.42257 5.41058C8.26629 5.2543 8.05433 5.1665 7.83331 5.1665H1.16665Z"
      fill="currentColor"
    />
  </SvgIcon>
);

export default IconSpecs;
