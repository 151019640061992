import { Paper } from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledPaper = styled(Paper)(({ theme, shiftAmount }) => ({
  '&.MuiPaper-root': {
    left: shiftAmount != null ? `-${shiftAmount}px` : null,
    position: 'relative',
  },
  '& .MuiAutocomplete-listbox': {
    borderRadius: theme.spacing(0),
    maxHeight: theme.spacing(37.75),
    fontSize: theme.spacing(2),
    fontFamily: theme.typography.fontFamily,
    '&::-webkit-scrollbar': {
      width: theme.spacing(1),
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: 'transparent',
    },
    '&::-webkit-scrollbar-thumb': {
      background: theme.palette.secondary.main,
      borderRadius: theme.spacing(1),
    },
    '& .MuiAutocomplete-option': {
      padding: theme.spacing(2),
    },
  },
}));

export default StyledPaper;
