import React from 'react';
import StyledBox from 'views/common/components/UI/MainWidget/IconedBox/IconedBox.styles';

const IconedBox = (props) => {
  const {
    children, Icon, className, ...restOfProps
  } = props;
  return (
    <StyledBox className={`${className} IconedBoxContainer`} {...restOfProps}>
      <Icon className="IBIcon" />
      {children}
    </StyledBox>
  );
};

export { IconedBox };
