import React from 'react';
import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import Skeleton from '@mui/material/Skeleton';

const StyledBox = styled(Box)(() => ({
  '&.cardSkeletonWrap': {
    boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 5px',
  },
}));

const BikeCardSkeleton = () => (
  <StyledBox className="cardSkeletonWrap">
    <Skeleton variant="rectangular" height={195} />
    <Box className="footer" px={1.5} py={0.5}>
      <Box my={0.5} mt={2.5}>
        <Skeleton variant="rectangular" width="30%" />
      </Box>
      <Box my={0.5} mb={3}>
        <Skeleton variant="rectangular" width="50%" />
      </Box>

      <Box my={0.5}>
        <Skeleton variant="rectangular" width="30%" />
      </Box>

      <Box my={0.5} mb={3}>
        <Skeleton variant="rectangular" width="40%" />
      </Box>
      <Box my={0.5} mb={1}>
        <Skeleton variant="rectangular" width="35%" />
      </Box>
    </Box>
  </StyledBox>
);
export { BikeCardSkeleton };
