import { useMediaQuery, useTheme } from '@mui/material';
import { useRef, useState, useEffect } from 'react';

const useShouldHydrate = (allow) => {
  const ref = useRef(null);
  const [render, setRender] = useState(typeof window === 'undefined');
  const isDesktop = useMediaQuery(useTheme().breakpoints.up('lg'));
  const isMobile = useMediaQuery(useTheme().breakpoints.down('md'));

  useEffect(() => {
    const isEmpty = ref.current?.innerHTML === '';
    if (isEmpty) {
      setRender(true);
    } else {
      switch (allow) {
        case 'desktop_only':
          if (isDesktop) setRender(true);
          break;
        case 'mobile_only':
          if (isMobile) setRender(true);
          break;
        default:
          break;
      }
    }
  }, [isMobile, isDesktop]);

  return [render, ref];
};

export default useShouldHydrate;
