import React from 'react';
import { styled } from '@mui/material/styles';
import { Typography } from '@mui/material';

const TypographyFontWeight500 = styled(Typography)(() => ({
  fontWeight: '700',
}));

const Typography700 = (props) => {
  const { children, ...other } = props;

  return (
    <TypographyFontWeight500 {...other}>{children}</TypographyFontWeight500>
  );
};

export { Typography700 };
