import React from 'react';
import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';
import ThumbUpAltOutlinedIcon from '@mui/icons-material/ThumbUpAltOutlined';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbDownAltOutlinedIcon from '@mui/icons-material/ThumbDownAltOutlined';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';

import IconButtonBordered from 'views/common/components/UI/IconButtonBordered';

const PREFIX = 'ReviewStates';

const classes = {
  ThumbUp: `${PREFIX}-ThumbUp`,
  ThumbDown: `${PREFIX}-ThumbDown`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const StyledBox = styled(Box)(({ theme }) => ({
  [`& .${classes.ThumbUp}`]: {
    padding: `${theme.spacing(0.5)} !important`,
    marginRight: theme.spacing(1.5),
    '&.Mui-disabled': {
      border: `1px solid ${theme.palette.success.main}`,
      color: theme.palette.success.main,
    },
  },

  [`& .${classes.ThumbDown}`]: {
    padding: `${theme.spacing(0.5)} !important`,
    transform: 'scaleX(-1)',
    '&.Mui-disabled': {
      border: `1px solid ${theme.palette.error.main}`,
      color: theme.palette.error.main,
    },
  },
}));

const ReviewStates = (props) => {
  const { currentUserVote, handleVotingChange } = props;

  const getLikeIcon = (currentUserStatus) => {
    switch (currentUserStatus) {
      case true:
        return <ThumbUpIcon fontSize="small" />;
      default:
        return <ThumbUpAltOutlinedIcon fontSize="small" />;
    }
  };

  const getDisLikeIcon = (currentUserStatus) => {
    switch (currentUserStatus) {
      case false:
        return <ThumbDownIcon fontSize="small" />;
      default:
        return <ThumbDownAltOutlinedIcon fontSize="small" />;
    }
  };

  return (
    <StyledBox>
      <IconButtonBordered
        color="success"
        className={classes.ThumbUp}
        onClick={() => handleVotingChange(true)}
        disabled={currentUserVote && true}>
        {getLikeIcon(currentUserVote)}
      </IconButtonBordered>

      <IconButtonBordered
        color="error"
        className={classes.ThumbDown}
        onClick={() => handleVotingChange(false)}
        disabled={currentUserVote == false && true}>
        {getDisLikeIcon(currentUserVote)}
      </IconButtonBordered>
    </StyledBox>
  );
};

export default ReviewStates;
