import React from 'react';
import { styled } from '@mui/material/styles';
import {
  Typography, Link, Button, Box,
} from '@mui/material';
import Rating from '@mui/material/Rating';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import UserInfoSubInfo from 'views/common/components/UI/UserInfoSubInfo';
import HelpfulReview from 'views/common/components/UI/HelpfulReview';
import EditReview from 'views/common/components/UI/MyReview/EditReview';
import { DetailedRating } from 'views/common/components';
import Typography500 from 'views/common/components/UI/Typography500';

const PREFIX = 'ReviewCard';

const classes = {
  reviewRow: `${PREFIX}-reviewRow`,
  user: `${PREFIX}-user`,
  rating: `${PREFIX}-rating`,
  ratingStars: `${PREFIX}-ratingStars`,
  starIcon: `${PREFIX}-starIcon`,
  arrowRight: `${PREFIX}-arrowRight`,
  reviewSubject: `${PREFIX}-reviewSubject`,
  reviewDes: `${PREFIX}-reviewDes`,
  reviewDetails: `${PREFIX}-reviewDetails`,
  reviewStars: `${PREFIX}-reviewStars`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled(Box)(({ theme, rateValue }) => ({
  [`&.${classes.reviewRow}`]: {
    paddingBottom: theme.spacing(4.25),

    '&:not(:last-of-type)': {
      paddingBottom: theme.spacing(1.125),
      marginBottom: theme.spacing(4.25),
      borderBottom: `1px solid ${theme.palette.grey['100']}`,
    },

    [theme.breakpoints.up('md')]: {
      '&:not(:last-child)': {
        marginBottom: theme.spacing(5),
      },
    },
    [theme.breakpoints.up('lg')]: {
      paddingBottom: theme.spacing(4),
    },
  },

  [`& .${classes.user}`]: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    marginBottom: theme.spacing(2),

    [theme.breakpoints.up('md')]: {
      marginBottom: theme.spacing(1.125),
      flexDirection: 'row',
      justifyContent: 'space-between',
    },

    [theme.breakpoints.down('md')]: {
      flexWrap: rateValue ? 'wrap' : null,
    },
  },

  [`& .${classes.rating}`]: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    alignItems: 'flex-start',
    marginBottom: theme.spacing(-1.25),
    [theme.breakpoints.up('md')]: {
      alignItems: 'flex-end',
      marginBottom: theme.spacing(-3),
    },
  },

  [`& .${classes.ratingStars}`]: {
    '&.MuiRating-root': {
      color: theme.palette.warning.main,
    },
    marginTop: theme.spacing(1.5),

    [theme.breakpoints.up('md')]: {
      marginTop: '0',
      justifyContent: 'flex-end',
    },
  },

  [`& .${classes.starIcon}`]: {
    '&.MuiRating-root': {
      color: theme.palette.warning.main,
    },
  },

  [`& .${classes.arrowRight}`]: {
    marginLeft: theme.spacing(0.5),
  },

  [`& .${classes.reviewSubject}`]: {
    fontWeight: 'bold',
  },

  [`& .${classes.reviewDes}`]: {
    color: theme.palette.secondary.main,
    whiteSpace: 'pre-line',
  },

  [`& .${classes.reviewDetails}`]: {
    position: 'relative',
    marginLeft: theme.spacing(0.5),

    '&:not(:last-child)': {
      marginBottom: theme.spacing(1.125),
    },
  },
  [`& .${classes.reviewStars}`]: {
    paddingTop: theme.spacing(0.5),
    paddingLeft: theme.spacing(2),
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(2, 0),
    },
    '& .RSitem': {
      flex: 'auto',
      '&:not(:last-child)': {
        paddingRight: theme.spacing(1),
        marginRight: theme.spacing(1),
      },
    },
  },
}));

const ReviewCard = (props) => {
  const {
    reviewId,
    onSubmitVote,
    userImage,
    userName,
    verified,
    reviewDate,
    rate,
    bikeName,
    bikeHref,
    reviewDetails,
    reviewVotes,
    editReviewDetails,
    rateValue,
    ratingComponentProps,
  } = props;

  const handleVotingChange = (vote) => {
    onSubmitVote(reviewId, vote);
  };

  return (
    <Root className={classes.reviewRow} key={userImage} rateValue={rateValue}>
      <Box className={classes.user}>
        <UserInfoSubInfo
          image={userImage}
          name={userName}
          verified={verified}
          date={reviewDate}
        />

        <Box className={classes.rating}>
          {rate && (
            <Rating
              readOnly
              value={rate}
              precision={0.5}
              emptyIcon={<StarBorderIcon fontSize="inherit" />}
              className={classes.ratingStars}
            />
          )}

          {rateValue && (
            <DetailedRating
              rateValue={rateValue}
              className={classes.reviewStars}
              ratingComponentProps={ratingComponentProps}
            />
          )}

          {bikeName && (
            <Button component={Link} href={bikeHref} color="primary">
              {bikeName}
              <ChevronRightIcon className={classes.arrowRight} />
            </Button>
          )}

          {editReviewDetails && (
            <Box py={1.25}>
              <EditReview reviewOverlayData={editReviewDetails} />
            </Box>
          )}
        </Box>
      </Box>

      {reviewDetails
        && reviewDetails.map((review) => (
          <Box key={review.title} className={classes.reviewDetails}>
            {review.subject && (
              <Typography variant="h5" className={classes.reviewSubject}>
                {review.subject}
              </Typography>
            )}

            {(review.rating || review.title) && (
              <Box
                mt={0.5}
                mb={1}
                display="flex"
                justifyContent="space-between"
                alignItems="center">
                {review.title && (
                  <Typography500
                    variant="h5"
                    component="p"
                    className={classes.reviewSummary}>
                    {review.title}
                  </Typography500>
                )}

                {review.rating && (
                  <Rating
                    readOnly
                    defaultValue={review.rating}
                    precision={0.5}
                    emptyIcon={<StarBorderIcon fontSize="inherit" />}
                    className={classes.starIcon}
                  />
                )}
              </Box>
            )}
            {review.description && (
              <Typography
                variant="subtitle2"
                component="p"
                className={classes.reviewDes}>
                {review.description}
              </Typography>
            )}
          </Box>
        ))}

      {reviewVotes && (
        <HelpfulReview reviewVotes={{ ...reviewVotes, handleVotingChange }} />
      )}
    </Root>
  );
};

export default ReviewCard;
