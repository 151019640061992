import React from 'react';
import { styled } from '@mui/material/styles';
import { Button } from '@mui/material';

const IconButtonBorderedDefault = styled(Button)(({ theme }) => ({
  '&.MuiButton-root': {
    minWidth: 'auto',
  },
  '&.MuiButton-outlined': {
    padding: theme.spacing(0.75),
  },
  '&.MuiButton-outlinedSecondary': {
    color: theme.palette.grey['300'],
    borderColor: theme.palette.grey[100],
  },
}));

const IconButtonBorderedError = styled(Button)(({ theme }) => ({
  '&.MuiButton-root': {
    minWidth: 'auto',
  },
  '&.MuiButton-outlined': {
    padding: theme.spacing(0.75),
    border: `1px solid ${theme.palette.error.main}`,
    color: theme.palette.error.main,
  },
}));

const IconButtonBorderedSuccess = styled(Button)(({ theme }) => ({
  '&.MuiButton-root': {
    minWidth: 'auto',
  },
  '&.MuiButton-outlined': {
    padding: theme.spacing(0.75),
    border: `1px solid ${theme.palette.success.main}`,
    color: theme.palette.success.main,
  },
}));

const IconButtonBordered = (props) => {
  const { children, color, ...other } = props;

  return (
    <>
      {color != 'error' && color != 'success' && (
        <IconButtonBorderedDefault color={color} variant="outlined" {...other}>
          {children}
        </IconButtonBorderedDefault>
      )}

      {color == 'error' && (
        <IconButtonBorderedError variant="outlined" {...other}>
          {children}
        </IconButtonBorderedError>
      )}

      {color == 'success' && (
        <IconButtonBorderedSuccess variant="outlined" {...other}>
          {children}
        </IconButtonBorderedSuccess>
      )}
    </>
  );
};

export default IconButtonBordered;
