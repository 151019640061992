import { mapLocaleToSubdomain } from 'lib/data/localesMap';

const { envVars } = require('next/config').default().publicRuntimeConfig;

const { API_PROTOCOL, EN_DOMAIN } = envVars;

const getLocalizedUrl = (url, locale) => {
  let subPath = locale !== 'en' ? `/${locale}` : '';
  let baseURL = `${API_PROTOCOL}://${EN_DOMAIN}`;

  if (mapLocaleToSubdomain[locale]) {
    baseURL = `${API_PROTOCOL}://${mapLocaleToSubdomain[locale]}`;
    subPath = '';
  }
  return `${baseURL}${subPath}${url}`;
};

export default getLocalizedUrl;
