import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledBox = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(6),
  [theme.breakpoints.down('lg')]: {
    marginBottom: theme.spacing(4.5),
    '& .RVRSViewAllBtn': {
      width: '100%',
    },
  },
  [theme.breakpoints.down('md')]: {
    marginBottom: theme.spacing(3),
  },
}));

export default StyledBox;
