/* eslint-disable react/no-children-prop */
import { createElement } from 'react';
import { useSelector } from 'react-redux';
import useShouldHydrate from 'views/common/hooks/useShouldHydrate';

// allow values [mobile_only, desktop_only, null]
// mobile_only : allow hydration only on mobile for lizards and both mobile and desktop for normal users
// desktop_only : allow hydration only on desktop for lizards and both mobile and desktop for normal users
// null : supresses hydration on both mobile/desktop for lizards and allow it for normal users

const HydrationWrapper = (props) => {
  const {
    children, allow = null, element = 'div', ...restOfProps
  } = props;
  const [render, ref] = useShouldHydrate(allow);
  const isCrawler = useSelector((state) => state.globalData.isCrawler);

  // render normally in case of a crawler , server, single page navigation
  if (render || !isCrawler) {
    return createElement(element, {
      ...restOfProps,
      children,
    });
  }
  // stop hydration otherwise
  return createElement(element, {
    ...restOfProps,
    ref,
    suppressHydrationWarning: true,
    dangerouslySetInnerHTML: { __html: '' },
  });
};

export { HydrationWrapper };
