import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';
import dynamic from 'next/dynamic';
import axios, { API } from 'lib/config/axios';
import { setAppData } from 'redux/features/globalData-slice';
import useErrorsHandler from 'views/common/hooks/useErrorsHandler';
import { useTranslation } from 'next-i18next';

import { REVIEW_SUBMIT, VOTE_SUBMIT, STAR_RATING } from 'lib/utils/CONSTANTS';
import { Reviews } from 'views/common/components/index';
import { notifyAirbrake } from 'lib/config/airbrake';

const AuthOverlay = dynamic(
  () => import('views/common/components/Logical/Auth/AuthOverlay'),
  { ssr: false },
);

const ReviewsWrapper = (props) => {
  const {
    reviewsData,
    reviewAPI,
    voteAPI,
    getPageData,
    setReviews,
    ratingLable,
    handleViewAllReviewsBtnClick,
    fullReviews = false,
  } = props;

  const [reviewError, setReviewError] = useState(null);
  const appState = useSelector((state) => state.globalData.appState);
  const dispatch = useDispatch();
  const handleErrorResponse = useErrorsHandler();
  const [hasReview, setHasReview] = useState(
    reviewsData && reviewsData.current_user_review_id !== null,
  );
  const [openAuthOverlay, setOpenAuthOverlay] = useState(false);

  const [openEditReviewOverlay, setOpenEditReviewOverlay] = useState(false);
  const [voteFormData, setVoteFormData] = useState();
  const [submitType, setSubmitType] = useState();
  const [openAddReviewOverlay, setOpenAddReviewOverlay] = useState(false);

  const [reviewFormData, setReviewFormData] = useState();
  const { t } = useTranslation('fe_er_common_reviews');

  const handleReviewFormChange = (e) => {
    setReviewFormData((prev) => ({
      ...prev,
      [e.target.name]:
        e.target.name === STAR_RATING ? +e.target.value : e.target.value,
    }));
  };
  const checkForUserReview = () => {
    let userReview;
    reviewsData?.reviews.forEach((review) => {
      if (reviewsData.current_user_review_id === review.id) {
        userReview = {
          title: review.title,
          body: review.body,
          star_rating: review.star_rating,
        };
      }
    });
    return userReview;
  };

  // For Add Review
  const handleAddReviewOverlayOpen = () => {
    setOpenAddReviewOverlay(true);
    setReviewFormData({ title: '', body: '', star_rating: '' });
  };
  const handleAddReviewOverlayClose = () => {
    setOpenAddReviewOverlay(false);
    setReviewError(null);
  };

  // For Edit Review
  const handleEditReviewOverlayOpen = () => {
    setOpenEditReviewOverlay(true);
    setReviewFormData(checkForUserReview());
  };
  const handleEditReviewOverlayClose = () => {
    setOpenEditReviewOverlay(false);
    setReviewError(null);
  };

  const handleAuthOverlaOpen = () => {
    setOpenAuthOverlay(true);
  };

  const handleAuthOverlayClose = () => {
    setOpenAuthOverlay(false);
  };

  const getUpdatedReviews = (reviewData, currentUser) => {
    const newReivew = {
      id: reviewData.id,
      reviewer: {
        avatar: {
          small_url: currentUser.avatar_url,
        },
        name: `${currentUser.first_name} ${currentUser.last_name}`,
      },
      created_at: dayjs().toISOString(),
      star_rating: reviewFormData.star_rating,
      title: reviewFormData.title,
      body: reviewFormData.body,
      votes: reviewData.votes,
      current_user_vote: reviewData.current_user_vote,
    };

    const updatedReviews = [newReivew];
    const reviewsLength = reviewsData.reviews.length;
    if (fullReviews) {
      if (!hasReview) {
        updatedReviews.push(...reviewsData.reviews);
      } else {
        updatedReviews.push(
          ...reviewsData.reviews.filter(
            (review) => reviewsData.current_user_review_id !== review.id,
          ),
        );
      }
    } else if (reviewsLength >= 1 && !hasReview) {
      updatedReviews.push({ ...reviewsData.reviews[0] });
    } else if (reviewsLength > 1 && hasReview) {
      updatedReviews.push(
        reviewsData.reviews.filter(
          (review) => reviewsData.current_user_review_id !== review.id,
        )[0],
      );
    }
    return updatedReviews;
  };

  const submitReview = (authToken, currentUser) => {
    const updatedReviewData = {
      ...reviewFormData,
      star_rating: Number(reviewFormData.star_rating),
    };

    axios
      .post(reviewAPI, {
        authenticity_token: authToken,
        review: updatedReviewData,
      })
      .then((res) => {
        const result = res.data;
        let updatedTotalCount;
        if (hasReview) {
          updatedTotalCount = reviewsData.total_count;
          handleEditReviewOverlayClose();
        } else {
          updatedTotalCount = reviewsData.total_count + 1;
          handleAddReviewOverlayClose();
        }

        setReviews({
          ...reviewsData,
          current_user_review_id: result.id,
          reviews: getUpdatedReviews(result, currentUser),
          total_count: updatedTotalCount,
        });
        setHasReview(true);
        setReviewError(null);
      })
      .catch((error) => {
        switch (error.response.status) {
          case 406:
            setReviewError(error.response.data);
            break;
          default:
            handleErrorResponse(error, false);
        }
      });
  };

  const handleSubmitReviewClick = () => {
    if (!appState.current_user) {
      handleAuthOverlaOpen();
      handleAddReviewOverlayClose();
      setSubmitType(REVIEW_SUBMIT);
    } else {
      submitReview(appState.authenticity_token, appState.current_user);
    }
  };

  const updateTargetReviewVote = (reviewId, newRank, vote) => {
    const clonedReviews = JSON.parse(JSON.stringify(reviewsData.reviews));

    const idx = clonedReviews.findIndex((review) => review.id === reviewId);
    clonedReviews[idx].votes = newRank;
    clonedReviews[idx].current_user_vote = vote;
    setReviews({
      ...reviewsData,
      reviews: clonedReviews,
    });
  };

  const submitVote = (reviewId, vote, authToken) => {
    axios
      .post(voteAPI.replace('{id}', reviewId), {
        authenticity_token: authToken,
        like: vote,
      })
      .then((res) => {
        updateTargetReviewVote(reviewId, res.data.review.new_rank, vote);
      })
      .catch((error) => {
        handleErrorResponse(error, false);
      });
  };

  const handleSubmitVoteCheckClick = (reviewId, vote) => {
    if (!appState.current_user) {
      handleAuthOverlaOpen();
      setVoteFormData({ reviewId, vote });
      setSubmitType(VOTE_SUBMIT);
    } else {
      submitVote(reviewId, vote, appState.authenticity_token);
    }
  };

  const handleSignCallback = async () => {
    try {
      const { data: newAppState } = await axios.get(API.layout);
      dispatch(
        setAppData({
          ...newAppState,
        }),
      );
      if (submitType === REVIEW_SUBMIT) {
        submitReview(newAppState.authenticity_token, newAppState.current_user);
      } else {
        submitVote(
          voteFormData.reviewId,
          voteFormData.vote,
          newAppState.authenticity_token,
        );
      }
      getPageData();
    } catch (error) {
      notifyAirbrake(error);
    }
  };
  const signInRedirectCallback = () => {
    handleSignCallback();
  };

  const signUpRedirectCallback = () => {
    handleSignCallback();
  };
  const getUserReview = () => reviewsData.reviews.filter(
    (review) => reviewsData.current_user_review_id === review.id,
  )[0];
  const getReviewsNotBelongToTheUser = () => reviewsData.reviews.filter(
    (review) => reviewsData.current_user_review_id !== review.id,
  );
  return (
    <>
      <Reviews
        currentUserReviewId={reviewsData.current_user_review_id}
        totalCount={reviewsData?.total_count}
        reviewError={reviewError}
        hasReview={hasReview}
        onSubmitVote={handleSubmitVoteCheckClick}
        addReviewState={{
          open: openAddReviewOverlay,
          handleOpen: handleAddReviewOverlayOpen,
          handleClose: handleAddReviewOverlayClose,
        }}
        editReviewState={{
          open: openEditReviewOverlay,
          handleOpen: handleEditReviewOverlayOpen,
          handleClose: handleEditReviewOverlayClose,
        }}
        reviewFormData={reviewFormData}
        onDataChange={handleReviewFormChange}
        reviews={getReviewsNotBelongToTheUser()}
        currentUserReview={getUserReview()}
        submitReviewBtn={{
          label: t('fe_er_common_reviews:save'),
          action: handleSubmitReviewClick,
        }}
        handleViewAllReviewsBtnClick={handleViewAllReviewsBtnClick}
        ratingLable={ratingLable}
      />
      {openAuthOverlay && (
        <AuthOverlay
          openAuthOverlay={openAuthOverlay}
          handleAuthOverlaOpen={handleAuthOverlaOpen}
          handleAuthOverlayClose={handleAuthOverlayClose}
          signInRedirectCallback={signInRedirectCallback}
          signUpRedirectCallback={signUpRedirectCallback}
        />
      )}
    </>
  );
};

export { ReviewsWrapper };
